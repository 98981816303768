.maincontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  overflow: hidden; 
}

.company-info {
  text-align: center;
  color: #536e96;
  margin-bottom: 20px;
}

.company-info h1 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.company-info p {
  font-size: 1rem;
  margin: 0;
}

.container {
  display: flex;
  justify-content: space-between;
  
}

.card {
  height: 280px;
  width: 200px;
  background-color: #ffb6c1;
  border-radius: 10px;
  box-shadow: -1rem 0 3rem #e75499;
  transition: 0.4s ease-out;
  position: relative;
  margin: 0 90px;
}

.card:not(:first-child) {
  margin-left: 0;
}

.card:hover {
  /* transform: translateY(20px); */
  transition: 0.4s ease-out;
}

.card:hover ~ .card {
  position: relative;
  left: 50px;
  transition: 0.4s ease-out;
}

.title {
  color: white;
  font-weight: 300;
  position: absolute;
  left: 20px;
  top: 15px;
}

.bar {
  position: absolute;
  top: 100px;
  left: 20px;
  height: 5px;
  width: 150px;
}

.emptybar {
  background-color: #2e3033;
  width: 100%;
  height: 100%;
}

.filledbar {
  position: absolute;
  top: 0px;
  z-index: 3;
  width: 0px;
  height: 100%;
  background: rgb(0, 154, 217);
  background: linear-gradient(
    90deg,
    rgba(0, 154, 217, 1) 0%,
    rgba(217, 147, 0, 1) 65%,
    rgba(255, 186, 0, 1) 100%
  );
  transition: 0.6s ease-out;
}

.card:hover .filledbar {
  width: 120px;
  transition: 0.4s ease-out;
}

.circle {
  position: absolute;
  top: 150px;
  left: calc(50% - 60px);
}

.stroke {
  stroke: #ff1493;
  stroke-dasharray: 360;
  stroke-dashoffset: 360;
  transition: 0.6s ease-out;
}

.svg {
  fill: #17141d;
  stroke-width: 2px;
}

.card:hover .stroke {
  stroke-dashoffset: 100;
  transition: 0.6s ease-out;
}

@media screen and (max-width: 780px) {
  .container {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the cards vertically */
  }

  .card {
    width: 95%; /* Leave some space around the cards */
    margin-right: 10px;
    margin: 10px;
  }

  .card img {
    width: 100%;
    height: auto;
  }

  .bar {
    width: 90%; /* Make the bar a bit smaller */
    margin: 20px auto; /* Add more space around the bar */
  }

  .emptybar {
    width: 100%;
  }

  .filledbar {
    width: 50%;
  }

  .circle {
    width: 60px; /* Make the circle a bit bigger */
    height: 60px;
    margin: 20px auto; /* Add more space around the circle */
  }
}