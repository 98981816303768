@tailwind base;
@tailwind components;
@tailwind utilities;
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@300;400;500;600;700&display=swap');
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

#root {
  font-family: 'Public Sans', sans-serif;

}

.input-box-shadow{
  filter: drop-shadow(-4px -4px 44px rgba(0, 0, 0, 0.08));
}

.slick-prev:before, 
.slick-next:before {
     color: black;
     font-size: 40px;
}

.slick-next:before{
  position: absolute;
  right:0;
   margin-top: -10px;
   margin-right: 5px;
}

.slick-next,
.slick-prev
{
  position: absolute;
  right:0;
 }

.slick-prev{
  z-index: 99999;
  margin-left: 25px;
}

.category-card:hover .arrow-icon{
  fill: white;
}

.slick-arrow{
  position: absolute;
  right:50;
  margin-right: 25px;
 }

.slick-dots {
  @apply ml-[-10px] hidden
 }




/* #root {
  max-width: 1280px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
}

.logo {
  height: 6em;
  padding: 1.5em;
  will-change: filter;
}
.logo:hover {
  filter: drop-shadow(0 0 2em #646cffaa);
}
.logo.react:hover {
  filter: drop-shadow(0 0 2em #61dafbaa);
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  a:nth-of-type(2) .logo {
    animation: logo-spin infinite 20s linear;
  }
}

.card {
  padding: 2em;
}

.read-the-docs {
  color: #888;
} */
